
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import FilterModel from "../../components/filter-model";
import getUTC from "@/function/getUTC";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import GetWebSocketLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/websocket-getter-service/GetWebSocketLogsResponse";
import WebSocketLogItem from "@/dataSource/api/logger-micro-service/services/models/request/websocket-getter-service/WebSocketLogItem";
import GetWebLogRequest from "@/dataSource/api/logger-micro-service/services/models/request/web-getter-service/GetWebLogRequest";

@Options({
  name: "WsActiveLogsView",
  components: {
    TableView,
  },
})
export default class WsActiveLogsView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;

  innerFilter: GetWebLogRequest;
  items: WebSocketLogItem[] = [];
  pageResponse: PageResponse = new PageResponse();

  takePage: number = 20;
  skipPage: number = 0;

  noPagination = false;
  isLoading = false;

  created() {
    if (this.filter && (this.filter.SkipPage || this.filter.SkipPage == 0)) {
      this.skipPage = this.filter.SkipPage;
    }
  }

  type(item: any) {
    switch (item.Level) {
      case EnumLogLevel.Error:
        return "error";
      case EnumLogLevel.Warn:
        return "warn";
      default:
        return "default";
    }
  }

  onFilterChange() {
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  mounted() {
    if (this.$route.name == "ws-logs-view") {
      this.refresh();
    }
  }

  // методы логов
  async refresh() {
    let response: GetWebSocketLogsResponse = null;
    this.isLoading = true;

    this.innerFilter = {
      OnlyActiveRequests: true,
      OnlyOpenedConnections: null,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      RequestId: this.filter.RequestId,
      Ip: this.filter.Ip,
      SessionToken: this.filter.SessionToken,
      Url: this.filter.Url,
      UserId: this.filter.UserId,

      LogLevel: this.filter.LogLevel,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Page: { Skip: this.skipPage, Take: this.takePage },
    };

    let tmpFilter = getUTC(this.innerFilter);
    try {
      this.items = [];
      response = await this.$api.WebSocketGetterService.getLogsAsync(tmpFilter);
      this.items = response.Items;
    } catch (error) {
      this.$notification.error("Ошибка получения данных!");
      console.log(`error:`, error);
      this.isLoading = false;
    }
    this.isLoading = false;

    this.pageResponse = response.Page;

    if (this.$refs.pagination) {
      (this.$refs.pagination as any).currentPage = this.skipPage / this.takePage + 1;
    }
  }

  // событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.takePage; // текущая страница * кол-во записей на странице
    if (this.skipPage === skip) {
      return;
    }

    this.skipPage = skip;
    this.updateQuery();

    this.refresh();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  updateQuery() {
    this.$emit("updateQuery", this.skipPage);
  }
}
